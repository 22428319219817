.minasu-content {
  &__meta {
    margin-bottom: 35px;
    color: $body-text;
  }

  &__date {
    margin-bottom: 5px;
  }

  &__details {
    color: $body-text;

    &--course {
      table {
        margin-left: -10px;

        @media(max-width: 767px) {
          display: block;
          margin-left: 0;
        }

        tr {
          @media(max-width: 767px) {
            display: block;
          }

          td {
            padding: 10px;
            vertical-align: top;

            &:nth-child(odd) {
              font-family: 'Futura W01', 'sans-serif';
              font-weight: 500;
              color: $highlight-blue;
            }

            &:nth-child(even) {
              @media(max-width: 767px) {
                margin-bottom: 20px;
              }
            }

            @media(max-width: 767px) {
              padding: 0;
              display: block;
              margin-bottom: 6px;
            }
          } // td

          p {
            margin-bottom: 0;
          }
        } // tr
      } // table
    } // &--course

    &:after {
      content: "";
      display: table;
      clear: both;
    }

    &-header {
      font-family: 'Futura W01', 'sans-serif';
      font-weight: 500;
      color: $body-text;
      margin-bottom: 30px;
      font-size: 1.375rem;
    }

    img {
      max-width: 100%;
      height: auto;
    }

    a {
      color: $link-main;
      text-decoration: underline;

      overflow-wrap: break-word;
      word-wrap: break-word;

      -ms-word-break: break-all;
      word-break: break-all;
      word-break: break-word;
      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;

      &:hover {
        color: $highlight-green;
      }
    } // a

    table {
      table-layout: fixed;
      width: 100%;
    }
  } // &__details
} // .minasu-content