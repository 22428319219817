.modal {
  &__title {
    font-size: 1.3rem;
    color: $header-text;
    //margin-bottom: 20px;
    font-weight: 600;

    @media(min-width: 768px) {
      font-size: 1.4375rem;
    }
  } // &__title

  .alert {
    margin-bottom: 0;
  }

  .button {
    margin-bottom: 0;
  }
} // .modal