.questions {
  $block: &;
  padding-left: 0;

  &__header {
    border-bottom: solid 1px $separator;
    padding: 14px 0;

    @media(min-width: 768px) {
      display: flex;
    }
  } // &__header

  &__header-title {
    font-size: 1.375rem;
    font-family: 'Futura W01', 'sans-serif';
    font-weight: 500;
    color: $header-text;
    text-transform: capitalize;
    margin-bottom: 0;

    #{$block}--admin & {
      font-family: 'Source Sans Pro', sans-serif;
    }
  } // &__header-title

  &__header-tags {
    display: flex;
    align-items: flex-start;
    flex: 1;
    margin-top: 20px;

    @media(min-width: 768px) {
      margin-top: 0;
    }
  } // &__header-tags

  &__item {
    list-style-type: none;
    display: flex;
    align-items: flex-start;
    padding: 20px 0;
    border-bottom: solid 1px $separator;

    @media(min-width: 768px) {
      padding: 20px;
    }

    @media(min-width: 1000px) {
      padding: 40px;
    }
  } // &__item

  &__answers {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $body-text;
  }

  &__answers-count {
    display: block;
    font-size: 1.5rem;
    line-height: 0.8;

    @media(min-width: 500px) {
      font-size: 1.9rem;
    }

    @media(min-width: 768px) {
      font-size: 2.125rem;
    }
  } // &__answers-count

  &__answers-label {
    display: block;
    font-size: 0.9rem;
    margin-top: 8px;
  }

  &__description {
    flex: 1;
    margin-left: 20px;
    transition: background .5s ease;

    @media(min-width: 500px) {
      margin-left: 30px;
    }

    @media(min-width: 768px) {
      margin-left: 45px;
    }
  } // &__description

  &__title {
    font-size: 1.2rem;
    font-family: 'Futura W01', 'sans-serif';
    font-weight: 500;
    letter-spacing: 0.2px;
    margin-bottom: 0;

    #{$block}--admin & {
      font-family: 'Source Sans Pro', sans-serif;
    }

    @media(min-width: 768px) {
      font-size: 1.53rem;
      letter-spacing: 0.5px;
    }
  } // &__title

  &__title-link {
    color: $link-main;
    transition: color .5s ease;

    &:hover {
      color: $highlight-green;
      text-decoration: none;
    }
  } // &__title-link

  &__meta {
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 0.9rem;
    color: $light-meta-text;
    opacity: 0;
    transition: opacity 0.5s ease;
  }

  &__author {
    color: $light-meta-text;

    &:hover {
      color: $highlight-green;
      text-decoration: none;
    }
  } // &__author

  &__text {
    display: none;

    @media(min-width: 768px) {
      display: block;
      color: $body-text;
      font-size: 0.95rem;
    }
  } // &__text
} // .questions