.login {
  background: linear-gradient(45deg, $highlight-blue, #6AAd4f);
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &__content {
    width: 100%;
    max-width: 288px;
    text-align: center;
    margin: 30px 15px;
  }

  &__message {
    color: #fff;
    margin-bottom: 25px;
    opacity: 0.75;
    letter-spacing: 0.5px;
  }

  &__logo {
    margin-bottom: 20px;
    width: 215px;
    height: 173px;

    @media(min-width: 800px) {
      margin-bottom: 30px;
      width: 230px;
      height: 161px;
    }

    @media(min-width: 1200px) {
      width: 288px;
      height: 216px;
    }
  } // &__logo  

  &__form-input {
    display: block;
    width: 100%;
    margin-bottom: 20px;
    border: 1px solid #B0CEDB;
    padding: 12px 20px;
    font-size: .875rem;
    border-radius: 50px;
    color: #518296;
  }
} // .login