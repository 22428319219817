@charset "UTF-8";

@font-face {
  font-family: "adac-app";
  src:url("icons/adac-app.eot");
  src:url("icons/adac-app.eot?#iefix") format("embedded-opentype"),
    url("icons/adac-app.woff") format("woff"),
    url("icons/adac-app.ttf") format("truetype"),
    url("icons/adac-app.svg#adac-app") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "adac-app" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "adac-app" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-user-icon:before {
  content: "\61";
}
.icon-flag:before {
  content: "\62";
}
.icon-pencil:before {
  content: "\64";
}
