.images {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-flow: row wrap;
  margin-left: -15px;
  margin-top: -15px;
  margin-bottom: 0;

  .question--admin &,
  .answers--admin &{
    max-width: 950px;
  }

  &--controls {
    margin-bottom: 1rem;
    opacity: 0;
    transition: all .5s ease;
  } // &--controls

  .question__content + &,
  .answers__answer + & {
    margin-top: 0;
  }

  &__item {
    position: relative;
    overflow: hidden;
    width: 50%;

    @media(min-width: 350px) {
      width: 33.333333333%;
    }

    @media(min-width: 500px) {
      width: 25%;
    }

    @media(min-width: 768px) {
      width: 16.666666667%;
    }

    @media(min-width: 1200px) and (max-width: 1399px) {
      width: 20%;
    }

    @media(min-width: 1400px) {
      width: 16.666666667%;
    }

    &:before {
      content: "";
      display: block;
      padding-top: 100%
    }
  } // &__item

  &__link {
    display: block;
    margin-top: 15px;
    margin-left: 15px;
    overflow: hidden;
    position:  absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  &__image {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    bottom: 0;
    right: 0;
    height: 100%;
    width: auto;
  }

  &__delete-form {
    position: absolute;
    top: 13.5px;
    right: 0;
  }

  &__delete {
    text-indent: -9999em;
    border-radius: 0;
    width: 25px;
    height: 25px;

    &::before,
    &::after {
      content: '';
      position: absolute;
      height: 2px;
      width: 20px;
      top: 12px;
      left: 3px;
      background: #fff;
      -webkit-transition: all .5s ease;
      transition: all .5s ease;
    }

    &::before {
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    &::after {
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  } // &__delete
} // .images