.text-editor {
  &__title {
    font-size: 1.3rem;
    color: $header-text;
    margin-bottom: 15px;
    font-weight: 600;

    @media(min-width: 768px) {
      font-size: 1.4375rem;
    }
  } // &__title

  &__notice {
    background: #FCF8E4;
    color: #695B1A;
    border-radius: 5px;
    padding: 18px 14px;
    margin-bottom: 18px;
    font-size: .9375rem;

    :last-child {
      margin-bottom: 0;
    }
  } // &__notice

  &__form-error {
    li {
      font-size: 80%;
      color: #dc3545;
    }
  } // &__form-error

  &__editor {
    margin-bottom: 10px;

    &--invalid {
      border: 1px solid #dc3545;
    }
  } // &__editor

  &__editor {
    margin-bottom: 10px;
  }

  &__files {
    margin-bottom: 25px;
  }
} // .text-editor