.search {
  margin-bottom: 30px;

  @media(min-width: 768px) {
    margin-bottom: 50px;
  }

  @media(min-width: 1000px) {
    margin-bottom: 65px;
  }

  &__form {
    max-width: 480px;
    width: 100%;
    margin-bottom: 0;

    @media(min-width: 768px) {
      margin-right: 25px;
    }
  } // &__form
  
  &__controls {
    display: flex;
    flex-direction: column;

    @media(min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
  } // &__controls

  &__filter-toggle {
    display: flex;
    align-items: center;
    margin-top: 15px;

    &:hover {
      cursor: pointer;
    }

    @media(min-width: 768px) {
      margin-top: 0;
    }
  } // &__filter-toggle

  &__filter-text {
    margin-right: 8px;
    color: $highlight-blue;
    font-weight: 600;
  }

  &__filter-icon {
    width: 22px;
    height: 20px;
  }

  &__tags {
    margin-top: 25px;
    display: none;
  }
} // .search