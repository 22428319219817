.page {
  $block: &;
  padding-top: 40px;
  padding-bottom: 40px;

  @media(min-width: 500px) {
    padding-top: 60px;
    padding-bottom: 80px;
  }

  @media(min-width: 768px) {
    padding-top: 80px;
    padding-bottom: 100px;
  }

  &__back {
    display: block;
    margin-bottom: 30px;
    color: $light-text;
    font-size: 1.1rem;
    font-family: 'Futura W01', 'sans-serif';
    font-weight: 400;
    transition: color .5s ease;

    @media(min-width: 768px) {
      font-size: 1.19rem;
      margin-bottom: 50px;
    }

    &::before {
      margin-right: 15px;
      border: solid $light-text;
      border-width: 0 2px 2px 0;
      padding: 6px;
      display: inline-block;
      content: '';
      transform: rotate(135deg);
      transition: border .5s ease;
    }

    &:hover {
      text-decoration: none;
      color: $active-link;

      &::before {
        border: solid $active-link;
        border-width: 0 2px 2px 0;
      }
    } // &:hover
  } // &__back

  &__header {
    align-items: center;
    margin-bottom: 30px;

    @media(min-width: 768px) {
      margin-bottom: 50px;
    }

    @media(min-width: 992px) {
      display: flex;
      justify-content: space-between;
    }

    @media(min-width: 1400px) {
      margin-bottom: 60px;
      margin-top: 20px;
    }

    #{$block}__title {
      margin-top: 0;
      margin-bottom: 0;
    }
  } // &__header

  &__title {
    font-size: 1.5rem;
    font-family: 'Futura W01', 'sans-serif';
    font-weight: 500;
    color: $header-text;
    margin-bottom: 30px;
    letter-spacing: 0.2px;

    @media(min-width: 768px) {
      font-size: 2rem;
      margin-bottom: 50px;
      letter-spacing: 0.5px;
    }

    @media(min-width: 1400px) {
      font-size: 2.44rem;
      margin-bottom: 60px;
      margin-top: 20px;
    }

    &--alt {
      color: $highlight-blue;
      margin-bottom: 25px;

      &::after {
        display: block;
        content: '';
        width: 65px;
        height: 1px;
        background: #C1C1C1;
        margin-top: 25px;
      }
    } // &--alt
  } // &__title

  &__nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
    flex: 1;
    margin-left: -18px;
    margin-top: 20px;

    @media(min-width: 600px) {
      display: flex;
      align-items: center;
    }

    @media(min-width: 992px) {
      justify-content: flex-end;
      margin-top: 0;
    }

    @media(min-width: 1400px) {
      margin-left: 25px;
    }

    @media(min-width: 1600px) {
      margin-left: -35px;
    }

    &-item {
      margin-left: 18px;
      margin-top: 5px;

      @media(min-width: 601px) {
        margin-top: 0;
      }

      @media(min-width: 1400px) {
        margin-left: 25px;
      }

      @media(min-width: 1600px) {
        margin-left: 35px;
      }
    } // &-item

    &-link {
      display: inline-block;
      position: relative;
      font-size: 1rem;
      font-family: 'Futura W01', 'sans-serif';
      font-weight: 500;
      letter-spacing: 0.5px;
      color: $alt-green;

      @media(min-width: 1400px) {
        font-size: 1.175rem;
      }

      #{$block}__nav-item--active & {
        &:before {
          transform: scaleX(1);
        }
      } // #{$block}__nav-item--active &

      &:before {
        content: '';
        position: absolute;
        transition: transform .5s ease;
        left: 0;
        bottom: -3px;
        width: 100%;
        height: 2px;
        background: $alt-green;
        transform: scaleX(0);
      } // &:before

      &:hover {
        color: $alt-green;
        text-decoration: none;

        &:before {
          transform: scaleX(1);
        }
      } // &:hover
    } // &-link

    .button {
      display: inline-block;
      margin-bottom: 0;
      font-family: 'Futura W01', 'sans-serif';
      font-weight: 500;

      @media(max-width: 599px) {
        padding: 0;
        color: $highlight-green;
        background: none;
        border: none;
      }

      &:hover {
        @media(max-width: 599px) {
          background: none;
          color: $highlight-green;
        }
      } // &:hover

      &:before{
        background: $highlight-green;

        @media(min-width: 600px) {
          display: none;
        }
      } // &:before
    } // .button
  } // &__nav

  &__notice {
    font-size: 0.9rem;
    color: $body-text;
    line-height: 1.5;
    margin: 10px 0;
  }
} // .page