.answers {
  $block: &;
  list-style-type: none;
  margin-bottom: 55px;
  
  &__header-title {
    color: $header-text;
    font-weight: 600;
    font-size: 1.125rem;
    border-bottom: 1px solid $separator;
    padding-bottom: 8px;

    #{$block}--admin & {
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  &__item {
    margin-top: 30px;
    border-bottom: 1px solid $separator;
    padding-bottom: 30px;

    #{$block}--admin & {
      padding: 10px 20px;
      border: 2px solid transparent;

      &--reported {
        border-color: #f1e09a;
        background-color: #fffcf1;
      }

      &--approved {
        border-color: #4dbd74;
        background-color: #f3fbf6;
      }

      &--archived {
        border-color: #f86c6b;
        background-color: #fff8f8;
      }
    } // #{$block}--admin &
  } // &__item

  &__avatar {
    color: #C1C1C1;
    font-size: 2.1625rem;
    margin-right: 15px;
    line-height: 1;

    &:hover {
      color: $highlight-green;
      text-decoration: none;
    }
  } // &__avatar

  &__meta {
    display: flex;
    margin-bottom: 18px;

    & > div {
      flex: 1;
    }
  } // &__meta

  &__meta-title {
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 2px;
  }

  &__meta-link {
    color: #707070;

    &:hover {
      color: $highlight-green;
      text-decoration: none;
    }
  } // &__meta-link

  &__meta-lower {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media(min-width: 768px) {
      justify-content: flex-start;
    }
  } // &__meta-lower

  &__meta-date {
    font-size: .875rem;
    color: $light-meta-text;
    margin-bottom: 0;
    opacity: 0;
    transition: opacity 0.5s ease;
  }

  &__content {
    margin-left: 50px;

    #{$block}--admin & {
      margin-left: 0;
    }
  } // &__content

  &__updated-text {
    font-size: .875rem;
    color: $light-meta-text;
    margin-bottom: 0;
  }
} // .answers