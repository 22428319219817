.resources {
  padding-left: 0;

  &__item {
    list-style-type: none;
    padding: 20px 0;
    border-bottom: solid 1px $separator;

    &:first-child {
      border-top: solid 1px $separator;
    }
  } // &__item

  &__title {
    font-size: 1rem;
    font-family: 'Futura W01', 'sans-serif';
    font-weight: 500;
    letter-spacing: 0.2px;

    @media(min-width: 768px) {
      font-size: 1.53rem;
      letter-spacing: 0.5px;
    }
  } // &__title

  &__title-link {
    color: #3A92B5;

    &:hover {
      color: $highlight-green;
      text-decoration: none;
    }
  } // &__title-link

  &__url {
    font-size: 0.9rem;
    font-weight: 500;
    letter-spacing: 0.2px;
    margin-bottom: 0;

    @media(min-width: 768px) {
      font-size: 1rem;
      letter-spacing: 0.5px;
    }
  } // &__link

  &__url-link {
    color: $highlight-green;

    &:hover {
      color: $highlight-green;
    }
  } // &__url-link

  &__description {
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 0.88rem;
    color: $body-text;

    @media(min-width: 768px) {
      font-size: 0.9375rem;
    }
  } // &__description
} // .resources