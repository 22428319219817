.digital-courses {
  $block: &;
  padding-left: 0;

  &__item {
    list-style-type: none;
    display: flex;
    align-items: center;

    &:first-child {
      #{$block}__description {
        border-top: solid 1px $separator;
      }
    } // &:first-child
  } //&__item

  &__description {
    display: block;
    flex: 1;
    margin-left: 18px;
    border-bottom: solid 1px $separator;
    padding: 15px;
    transition: background .5s ease;

    @media(min-width: 768px) {
      padding: 30px 15px;
      margin-left: 30px;
    }

    &:hover {
      text-decoration: none;
      background-color: #F7FCFE;

      #{$block}__title {
        color: $highlight-green;
      }
    } // &:hover
  } // &__description

  &__meta {
    margin-bottom: 10px;

    @media(min-width: 768px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__title {
    color: $link-main;
    font-size: 1rem;
    font-family: 'Futura W01', 'sans-serif';
    font-weight: 500;
    letter-spacing: 0.2px;
    margin-bottom: 0;
    transition: color .5s ease;
    flex: 1;

    @media(min-width: 768px) {
      font-size: 1.53rem;
      letter-spacing: 0.5px;
    }
  } // &__title

  &__date {
    display: none;

    @media(min-width: 768px) {
      display: block;
      margin-top: 0;
      margin-left: 15px;
      color: $light-text;
      font-size: 0.88rem;
    }
  } // &__date

  &__text {
    display: none;
    font-size: 0.9rem;
    color: $body-text;
    line-height: 1.5;

    @media(min-width: 768px) {
      display: block;
      font-size: 0.94rem;
    }

    &--short {
      display: block;

      @media(min-width: 768px) {
        display: none;
      }
    } // &--short
  } // &__text

  &__credits {
    color: $primary-green;
    font-weight: 600;
    font-size: 0.875rem;

    @media(min-width: 768px) {
      font-size: 1rem;
    }
  } // &__credits
} // .digital-courses