body {
  font-size: 16px;
  font-family: 'Source Sans Pro', sans-serif;
}

.container {
  @media(min-width: 1400px) {
    max-width: 1340px;
  }

  @media(min-width: 1600px) {
    max-width: 1540px;
  }
}

.form-group {
  ul:not(.select2-selection__rendered) {
    font-size: 80%;
    color: #dc3545;
  }

  ul + input,
  ul + select,
  ul + textarea {
    border: 1px solid #dc3545;
  }

  ul + select + .select2 .select2-selection {
    border-color: #dc3545;
  }
}