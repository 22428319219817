@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=b0287360-17f8-43fc-a585-3f1c6add2555");
@font-face{
  font-family:"Futura W01_n4";
  src:url("fonts/cf053eae-ba1f-44f3-940c-a34b68ccbbdf.eot?#iefix") format("eot")
}
@font-face{
  font-family:"Futura W01";
  src:url("fonts/cf053eae-ba1f-44f3-940c-a34b68ccbbdf.eot?#iefix");
  src:url("fonts/cf053eae-ba1f-44f3-940c-a34b68ccbbdf.eot?#iefix") format("eot"),url("fonts/8bf38806-3423-4080-b38f-d08542f7e4ac.woff2") format("woff2"),url("fonts/e2b9cbeb-fa8e-41cd-8a6a-46044b29ba52.woff") format("woff"),url("fonts/c2a69697-4f06-4764-abd4-625031a84e31.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face{
  font-family:"Futura W01_n5";
  src:url("fonts/0a41510f-c527-4001-89c5-d66d2c9ed34b.eot?#iefix") format("eot")
}
@font-face{
  font-family:"Futura W01";
  src:url("fonts/0a41510f-c527-4001-89c5-d66d2c9ed34b.eot?#iefix");
  src:url("fonts/0a41510f-c527-4001-89c5-d66d2c9ed34b.eot?#iefix") format("eot"),url("fonts/e17c7943-8db2-4470-9e2a-74a8d01f6776.woff2") format("woff2"),url("fonts/6c72b0cb-89a1-4da9-8574-9244967bd9d6.woff") format("woff"),url("fonts/dd79c75a-b0c9-4d70-a5a7-bdde1d5449fd.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}