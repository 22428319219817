.markdown-content {
  color: $body-text;

  &--digital-course {
    margin-bottom: 15px;
  }

  a {
    color: $link-main;

    &:hover {
      color: $highlight-green;
      text-decoration: none;
    }
  } // a

  :last-child {
    margin-bottom: 0;
  }

  ul {
    padding-left: 18px;

    li {
      text-indent: -5px;
    }
  } // ul

  ol {
    padding-left: 18px;
  }
} // .markdown-content