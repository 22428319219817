.post-controls {
  margin-left: 10px;
  display: flex;

  &__item {
    display: block;
    color: $light-meta-text;
    line-height: 1rem;
    margin-left: 3px;
    font-size: 1.1rem;

    &:hover {
      color: $highlight-green;
      text-decoration: none;
    }

    &--reported {
      color: $reported-red;

      &:hover {
        color: $reported-red-hover;
      }
    }
  }

  // &__item
}

// .post-controls
