$linkMargin: 8;
$linkMarginLarge: 16;

.site-header {
  $block: &;
  padding-top: 15px;
  padding-bottom: 15px;
  background-image: linear-gradient(to left, #7AB664, #5EA1BC);

  @media(min-width: 1400px) {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  &__wrapper {
    justify-content: space-between;
  }

  &__nav-wrapper {
    .navbar-expand-lg & {
      display: none !important;

      @media(min-width: 1200px) {
        display: flex !important;
      }
    }
  } // &__nav-wrapper

  &__nav {
    width: 100%;
    justify-content: flex-end;
  }

  &__logo {
    width: 215px;
    height: 84px;

    @media(min-width: 1400px) {
      width: 276px;
      height: 108px;
    }
  } // &__logo

  &__toggle {
    display: flex;
    margin: 1px 0;
    justify-content: flex-start;
    align-items: center;

    @media (min-width: 1200px) {
      display: none;
    } // min-width: 1200px

    &:hover {
      cursor: pointer;
    } // &:hover
  } // &__mobile-toggle

  &__toggle-text {
    font-family: 'Futura W01', 'sans-serif';
    font-weight: 500;
    color: #ffffff;
    margin-left: 10px;
    font-size: 0.9rem;
  }

  &__toggle-bar {
    display: block;
    width: 20px;
    height: 2px;
    background: #fff;
    margin: 3px 0;
  } // &__toggle-bar

  &__nav-link {
    color: #ffffff;
    font-family: 'Futura W01', 'sans-serif';
    font-weight: 500;
    letter-spacing: 0.2px;
    font-size: 0.94rem;
    position: relative;

    @media(min-width: 768px) {
      letter-spacing: 0.5px;
    }

    @media(min-width: 1400px) {
      font-size: 1.06rem;
    }

    #header & {
      padding-left: $linkMargin * 1px;
      padding-right: $linkMargin * 1px;

      @media(min-width: 1400px) {
        padding-left: $linkMarginLarge * 1px;
        padding-right: $linkMarginLarge * 1px;
      }
    } // #header &

    &:focus {
      outline: none;
    }

    &:before {
      content: '';
      position: absolute;
      transition: transform .5s ease;
      left: $linkMargin * 1px;
      bottom: 3px;
      width: calc(100% - ((2 * #{$linkMargin}) * 1px ));
      height: 2px;
      background: #ffffff;
      transform: scaleX(0);

      @media(min-width: 1400px) {
        left: $linkMarginLarge * 1px;
        width: calc(100% - ((2 * #{$linkMarginLarge}) * 1px ));
      }
    } // &:before

    &:hover {
      color: #ffffff;

      &:before {
        transform: scaleX(1);
      }
    }

    #{$block}__nav-item--active & {
      &:before {
        transform: scaleX(1);
      }
    } // #{$block}__nav-item--active &

    &--dropdown {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;

      #header & {
        padding-left: 39px;

        @media(min-width: 1400px) {
          padding-left: 47px;
        }
      } // #header &

      &:after {
        line-height: 1;
        font-size: 1.35rem;
        content: "\61";
        font-family: 'adac-app';
        position: absolute;
        left: $linkMargin * 1px;
        top: 50%;
        margin-top: -11px;

        @media(min-width: 1400px) {
          left: $linkMarginLarge * 1px;
        }
      }
    } // &--dropdown
  } // &__nav-link

  &__dropdown {
    left: $linkMargin * 1px;
    border-radius: 0;

    @media(min-width: 1400px) {
      left: $linkMarginLarge * 1px;
    }

    &-item {
      font-family: 'Futura W01', 'sans-serif';
      font-weight: 500;
      -webkit-font-smoothing: antialiased;
      color: #7B7B7B;
      padding-left: 15px;
      padding-right: 15px;
      font-size: 0.94rem;

      @media(min-width: 1400px) {
        font-size: 1.06rem;
      }

      &:hover,
      &:active
      {
        color: $active-link;
        transition: transform .5s ease;
        background: transparent;
      }
    } // &-item
  } // &__dropdown

  &__username {
    max-width: 130px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__dropdown-toggle {
    margin-left: 8px;
    position: relative;
    top: 2px;
    width: 11px;
  }
} // .header