.pagination {
  $block: &;
  margin-top: 30px;
  justify-content: center;
  margin-left: -8px;
  margin-bottom: -8px;
  flex-wrap: wrap;

  .card & {
    margin-top: 0;
  }

  @media (min-width: 700px) {
    margin-top: 50px;
  }

  &__item {
    margin-left: 8px;
    margin-bottom: 8px;
    display: none;

    @media (min-width: 700px) {
      display: block;
    }

    &--current,
    &--arrow {
      display: block;
    }

    &--current {
      #{$block}__link {
        color: #fff;
        background: $primary-green;
        border-color: $primary-green;
      } // #{$block}__link
    } // &--current

    &--disabled {
      #{$block}__link {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        line-height: 1;
        background: none;
        cursor: default;
        color: #8AA947;
        border-color: transparent;
        width: auto;
        padding: 0 5px;

        &:hover {
          border-color: transparent;
          background: none;
          color: #8AA947;
        }
      } // #{$block}__link
    } // &--disabled
  } // &__item

  &__link {
    position: relative;
    font-size: 1.125rem;
    background: #C5E2CA;
    border-radius: 5px;
    border: 1px solid #C5E2CA;
    color: #719F91;
    min-width: 40px;
    height: 40px;
    text-align: center;
    transition: all .5s ease;

    &:hover {
      color: #fff;
      background: $primary-green;
      border-color: $primary-green;

      #{$block}__arrow {
        border: solid #fff;
        border-width: 0 2px 2px 0;
      }
    } // &:hover
  } // &__link

  &__arrow {
    border: solid #719F91;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 5px;
    position: relative;

    &--back {
      transform: rotate(135deg);
      right: -2.5px;
    }

    &--forward {
      transform: rotate(-45deg);
      left: -2.5px;
    }
  } // &__arrow
} // .pagination