.button {
  display: block;
  text-align: center;
  border-radius: 50px;
  border: 1px solid $highlight-green;
  background: $highlight-green;
  padding: 15px 65px;
  font-size: 1.15rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: 10px;
  transition: all 0.5s ease;

  @media(min-width: 400px) {
    display: inline-block;
  }

  @media(min-width: 768px) {
    padding: 15px 105px;
    font-size: 1.25rem;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
    text-decoration: none;
    background: #2286AD;
    color: #fff;
    border-color: #2286AD;
  }

  &--disabled {
    color: #ACACAC;
    background: #E3E3E3;
    border-color: #E3E3E3;

    &:hover {
      color: #ACACAC;
      background: #E3E3E3;
      border-color: #E3E3E3;
      cursor: default;
    }
  } // &--disabled

  &--login {
    background: none;
    border-color: #fff;
    font-size: .875rem;
    margin-bottom: 0;
    display: block;
    width: 100%;
    padding: 12px 20px;

    &:hover {
      background: #fff;
      border-color: #fff;
      color: $highlight-blue;
    }
  } // &--login

  &--search {
    padding: 8.5px 20px;
    font-size: 0.9rem;
    margin-bottom: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &--form {
    padding: 8px 22px;
    font-size: 1rem;

    @media(min-width: 1400px) {
      padding: 10px 30px;
      font-size: 1.175rem;
    }
  } // &--form

  &--loader {
    display: flex;
    justify-content: space-between;
    align-content: center;
  } // &--loader

  &--small {
    padding: 8px 16px;
    font-size: 1rem;

    @media(min-width: 1400px) {
      padding: 10px 20px;
      font-size: 1.175rem;
    }
  } // &--small

  &--upload {
    background: #71b0ae;
    border-color: #71b0ae;
    font-size: 0.94rem;
    padding: 8px 15px;

    @media(min-width: 1400px) {
      font-size: 1rem;
      padding: 8px 18px;
    }

    .loader {
      top: 1px;

      @media(min-width: 1400px) {
        top: 2px;
      }
    } // .loader
  } // &--upload

  &--secondary {
    background-color: #5a6268;
    border-color: #5a6268;
  }
} // .button
