.tags {
  padding-left: 0;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-top: -5px;

  @media(min-width: 768px) {
    margin-left: -8px;
    margin-top: -8px;
  }

  @media(min-width: 1000px) {
    margin-left: -12px;
    margin-top: -12px;
  }

  &--reset {
    margin-bottom: 0;
    flex: 1;

    @media(min-width: 768px) {
      margin-left: 5px;
    }
  } // &--reset

  &__item {
    margin-left: 5px;
    margin-top: 5px;
    list-style-type: none;

    @media(min-width: 768px) {
      margin-left: 8px;
      margin-top: 8px;
    }

    @media(min-width: 1000px) {
      margin-left: 12px;
      margin-top: 12px;
    }
  } // &__item

  &__link {
    display: block;
    background: #EAF5EC;
    border-radius: 50px;
    color: #2B6762;
    padding: 4px 12px;
    font-size: 0.85rem;

    @media(min-width: 768px) {
      font-size: 0.9rem;
      padding: 6px 18px;
    }

    @media(min-width: 1000px) {
      font-size: 1rem;
      padding: 6px 25px;
    }

    &:hover {
      background: #D6EDDC;
      text-decoration: none;
      color: #1B8069;
    }

    &--active {
      background: #D6EDDC;
      color: #1B8069;
    }

    &--reset {
      position: relative;
      padding-left: 40px;
      font-weight: 400;

      &::before,
      &::after {
        content: '';
        position: absolute;
        height: 16px;
        width: 1.5px;
        top: 50%;
        left: 22px;
        margin-top: -8px;
        background: #92A8A6;

        @media(min-width: 768px) {
          height: 16px;
          width: 1.5px;
          left: 22px;
          margin-top: -8px;
        }

        @media(min-width: 1000px) {
          height: 16px;
          width: 1.5px;
          left: 22px;
          margin-top: -8px;
        }
      }

      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }
    } // &--reset

    &--clear {
      margin-left: 5px;
      color: $body-text;
      background: none;
      border: 1px solid #A0A0A0;

      &:hover {
        background: #f1f1f1;
        color: $body-text;
      }

      &::before,
      &::after {
        background: #A0A0A0;
      }

      @media(min-width: 768px) {
        margin-left: 8px;
      }

      @media(min-width: 1000px) {
        margin-left: 12px;
      }
    } // &--clear

    &--small {
      padding: 4px 12px;
      font-size: 0.85rem;
    }
  } // &__link
} // .tags