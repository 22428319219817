.question {
  margin-bottom: 65px;

  &--admin {
    padding: 20px;
    border: 2px solid transparent;
  }

  &--reported {
    border-color: #f1e09a;
    background-color: #fffcf1;
  }

  &--approved {
    border-color: #4dbd74;
    background-color: #f3fbf6;
  }

  &--archived {
    border-color: #f86c6b;
    background-color: #fff8f8;
  }

  &__title {
    font-size: 1.3rem;
    color: $header-text;
    border-bottom: solid 1px $separator;
    padding-bottom: 11px;
    margin-bottom: 20px;
    font-weight: 600;

    @media(min-width: 768px) {
      font-size: 1.4375rem;
    }
  } // &__title

  &__footer {
    margin-top: 30px;

    @media(min-width: 768px) {
      display: flex;
      align-items: center;
      flex-flow: row wrap;
      justify-content: space-between;
    }

    .tags {
      flex: 1;
      margin-bottom: 0;
    }
  } // &__footer

  &__meta-wrapper {
    display: flex;
    margin-top: 15px;
    justify-content: space-between;
    align-items: center;

    @media(min-width: 768px) {
      margin-top: 0;
      margin-left: 15px;
    }
  } // &__meta-wrapper

  &__meta {
    flex-shrink: 1;
    color: $light-meta-text;
    margin-bottom: 0;
    opacity: 0;
    transition: opacity 0.5s ease;
  } // &__meta

  &__author {
    color: $light-meta-text;

    &:hover {
      color: $highlight-green;
      text-decoration: none;
    }
  } // &__author
} // .question