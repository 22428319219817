.progress-icon {
  $block: &;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 1px solid $primary-green;

  @media(min-width: 768px) {
    width: 45px;
    height: 45px;
  }

  &--quiz {
    #{$block}__inner {
      border: 4px solid #fff;
      background-image:
        linear-gradient(-90deg, $primary-green 50%, transparent 50%),
        linear-gradient(0deg, $primary-green 50%, transparent 50%);
    }
  } // &--quiz

  &--complete {
    background-color: $primary-green;

    #{$block}__inner {
      background-image: none;
    }

    #{$block}__check-mark {
      display: block;
    }
  } // &--complete

  &__check-mark {
    display: none;
    width: 18px;
    height: auto;
  }

  &__inner {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
} // .progress-icon