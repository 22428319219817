.upcoming-events {
  $block: &;
  padding-left: 0;

  &__item {
    list-style-type: none;

    &:first-child {
      #{$block}__link {
        border-top: solid 1px $separator;
      }
    } // &:first-child
  } //&__item

  &__link {
    display: flex;
    flex-direction: column;
    border-bottom: solid 1px $separator;
    padding: 25px 20px;
    transition: background .5s ease;

    @media(min-width: 401px) {
      flex-direction: row;
    }

    @media(min-width: 768px) {
      padding: 35px 25px;
    }

    &:hover {
      text-decoration: none;
      background-color: #F7FCFE;

      #{$block}__title {
        color: $highlight-green;
      }
    } // &:hover
  } // &__link

  &__calendar {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: linear-gradient(to bottom, #65AA9B, #60A4B2);
    border-radius: 3px;
    width: 80px;
    padding: 10px;
    font-family: 'Futura W01', 'sans-serif';
    letter-spacing: 0.2px;
    color: #fff;
    text-transform: uppercase;

    @media(min-width: 768px) {
      width: 105px;
      padding: 15px;
      letter-spacing: 0.5px;
    }

    &-day {
      display: block;
      font-size: 1.8rem;

      @media(min-width: 768px) {
        font-size: 1.9875rem;;
      }
    } // &-day

    &-month {
      font-size: 1.325rem;
      display: block;

      @media(min-width: 768px) {
        font-size: 1.425rem;
      }
    } // &-month
  } // &__calendar

  &__details {
    margin-left: 0;
    margin-top: 20px;
    flex-shrink: 1;

    @media(min-width: 401px) {
      margin-left: 20px;
      margin-top: 0;
    }
  } // &__details

  &__title {
    color: $link-main;
    font-size: 1rem;
    margin-top: 2px;
    font-family: 'Futura W01', 'sans-serif';
    font-weight: 500;
    letter-spacing: 0.2px;
    margin-bottom: 5px;
    transition: color .5s ease;

    @media(min-width: 768px) {
      font-size: 1.43rem;
      letter-spacing: 0.5px;
    }
  } // &__title

  &__date {
    color: $light-meta-text;
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 0.96875rem;

    @media(min-width: 768px) {
    }
  } // &__date

  &__info {
    margin-bottom: 0;
    font-size: 0.88rem;
    color: $body-text;

    @media(min-width: 768px) {
      font-size: 0.9375rem;
    }
  } // &__info
} // .upcoming-courses