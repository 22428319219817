.digital-course {
  &__meta {
    display: flex;
    font-weight: 600;
    margin-bottom: 20px;

    @media(min-width: 768px) {
      margin-bottom: 30px;
    }
  } // &__meta

  &__credit {
    color: #6CB478;
  }

  &__bullet,
  &__date {
    margin-left: 4px;
    color: $light-text;
  }

  &__description {
    font-weight: 300;
    color: $body-text;
    font-size: 1.2rem;
    line-height: 2rem;
    margin-bottom: 20px;

    @media(min-width: 768px) {
      font-size: 1.375rem;
    }
  } // &__description

  &__details {
    color: $body-text;
    line-height: 1.5rem;
    margin-bottom: 30px;
  } // &__details

  &__notice-wrapper {
    padding: 25px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F4F9F5;
    border-radius: 5px;

    @media(min-width: 1000px) {
      padding: 35px;
    }
  } // &__notice-wrapper

  &__check-mark {
    width: 25px;
    height: 17px;
    margin-right: 25px;

    @media(min-width: 1000px) {
      width: 36px;
      height: 25px;
      margin-right: 40px;
    }
  } // &__check-mark

  &__notice {
    font-size: 1.05rem;
    color: $primary-green;
    margin-bottom: 0;

    @media(min-width: 1000px) {
      font-size: 1.25rem;
    }
  } // &__notice

  &__video {
    margin-top: 30px;

    @media(min-width: 768px) {
      margin-top: 40px;
    }

    @media(min-width: 1000px) {
      margin-top: 45px;
    }
  } // &__video

  &__quiz-access {
    margin-top: 30px;

    @media(min-width: 768px) {
      margin-top: 40px;
    }

    @media(min-width: 1000px) {
      margin-top: 45px;
    }
  } // &__quiz-access

  &__quiz-instructions {
    color: #A8A8A8;
    font-size: 0.9rem;
  }
} // .digital-course