.profile {
  &__user-icon {
    line-height: 1;
    position: relative;
    top: 3px;
  }

  &__credits {
    color: $body-text;
    margin-bottom: 10px;
  }

  &__header {
    color: $header-text;
    font-weight: 600;
    font-size: 1.125rem;
  }

  &__credits-info {
    margin-bottom: 0;
  }

  &__link {
    color: $link-main;

    &:hover {
      color: $highlight-green;
      text-decoration: none;
    }
  } // &__link
} // .profile