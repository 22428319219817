$active-link: #66b77e;
$primary-green: #6CB478;
$header-text: #5D5D5D;
$body-text: #717171;
$light-text: #ADADAD;
$light-meta-text: #A0A0A0;
$link-main: #3A92B5;
$highlight-green: #80BC00;
$highlight-blue: #3F89A7;
$separator: #DDDDDD;
$alt-green: #256781;
$reported-red: #e65c5c;
$reported-red-hover: #c34747;
