.home-nav {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: center;
  margin: 20px auto 70px auto;

  @media(min-width: 550px) {
    width: 75%;
  }

  @media(min-width: 1200px) {
    width: 60%;
    margin: 30px auto 100px auto;
  }

  @media(min-width: 1600px) {
    width: 52%;
  }

  &__item {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;

    @media(min-width: 350px) {
      flex: 0 0 50%;
      max-width: 50%;
      margin-top: 50px;
    }

    @media(min-width: 768px) {
      max-width: 33.33%;
      flex: 0 0 33.33%;
    }

    @media(min-width: 1200px) {
      margin-top: 70px;
    }

    &:hover {
      text-decoration: none;
    }
  } // &__item

  &__circle {
    padding: 20% 10%;
    width: 115px;
    height: 115px;
    background-image: linear-gradient(#2286AD, #55A0B2);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-bottom: 10px;

    @media(min-width: 450px) {
      width: 135px;
      height: 135px;
    }

    @media(min-width: 992px) {
      width: 150px;
      height: 150px;
    }

    @media(min-width: 1400px) {
      width: 175px;
      height: 175px;
    }

    a:hover & {
      background-image: linear-gradient(#4899B1, #6CACB4);
    }
  } // &__circle

  &__image {
    max-width: 80%;
    height: auto;
    width: 100%;
    display: block;
  }

  &__text {
    font-family: 'Futura W01', 'sans-serif';
    font-weight: 500;
    text-transform: capitalize;
    font-size: 1.05rem;
    color: $highlight-blue;

    @media(min-width: 992px) {
      font-size: 1.15rem;
    }

    @media(min-width: 1400px) {
      font-size: 1.25rem;
    }

    a:hover & {
      color: #66b77e;
    }
  } // &__text
} // .nav-items