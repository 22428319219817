.loader {
  opacity: 1;
  position: relative;
  top: 4px;
  margin-left: 10px;
  font-size: 2px;
  text-indent: -9999px;
  border-top: 2px solid rgba(255, 255, 255, 0.2);
  border-right: 2px solid rgba(255, 255, 255, 0.2);
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  border-left: 2px solid #ffffff;
  transform: translateZ(0);
  animation: loader 1.1s infinite linear;
  transition: all 0.5s ease;
  border-radius: 50%;
  width: 22px;
  height: 22px;

  &--hidden {
    opacity: 0;
    font-size: 0;
    margin-left: 0;
    width: 0;
    height: 0;

    &:after {
      width: 0;
      height: 0;
    }
  } // &--hidden

  &:after {
    border-radius: 50%;
    width: 22px;
    height: 22px;
  }
} // .loader

@-webkit-keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
