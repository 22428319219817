.sidebar {
  $block: &;
  background: $alt-green;
  right: -260px;
  width: 260px;
  position: fixed;

  &__wrapper {
    position: relative;
    padding: 45px 30px;
  }

  &__menu {
    list-style-type: none;
    padding-left: 0;
  }

  &__user-icon {
    font-size: 28px;
    line-height: 0.8;
    margin-right: 7px;
  }

  &__username {
    max-width: 130px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__menu-item {
    padding: 10px 0;

    &--user {
      padding-top: 0;
      margin-bottom: 20px;
    }

    &--logout {
      margin-top: 40px;
    }
  } // &__menu-item

  &__menu-link {
    display: block;
    color: #ffffff;
    font-family: 'Futura W01', 'sans-serif';
    font-weight: 500;
    letter-spacing: 0.2px;
    font-size: 0.94rem;
    padding: 5px 0;

    @media(min-width: 768px) {
      letter-spacing: 0.5px;
    }

    &:hover {
      text-decoration: none;
      color: $active-link;
      transition: all .5s ease;
    }

    #{$block}__menu-item--active & {
      color: $active-link;
    } // #{$block}__menu-item--active &

    &--user {
      padding-left: 36px;
      position: relative;

      &:after {
        line-height: 1;
        font-size: 28px;
        content: '\61';
        font-family: 'adac-app';
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -14px;
      }
    } // &--user

    &--logout {
      font-size: 0.88rem;
      color: rgba(#ffffff, 0.68);
    }
  } // &__menu-link

  &__close {
    position: absolute;
    top: 15px;
    right: 12px;

    width: 25px;
    height: 25px;
    overflow: hidden;

    &:hover {
      &::before,
      &::after {
        background: $active-link;
      }
    } // &:hover

    &::before,
    &::after {
      content: '';
      position: absolute;
      height: 2px;
      width: 100%;
      top: 50%;
      left: 0;
      margin-top: -1px;
      background: #fff;
      transition: all .5s ease;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  } // &__close
} // .sidebar
