.user-notification {
  background: #F0F9FC;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  &--warning {
    background: #fbf9e4;
  }

  @media(min-width: 450px) {
    padding: 45px;
  }

  &__image {
    width: 40px;
    height: 40px;
  }

  &__text {
    margin-left: 20px;
    color: #417084;
    margin-bottom: 0;
    font-family: 'Source Sans Pro', sans-serif;
    flex-shrink: 1;
  }
} // .user-notification