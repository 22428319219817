.tui-editor-contents {
  color: $body-text;

  a {
    color: $link-main;

    &:hover {
      color: $highlight-green;
      text-decoration: none;
    }
  }

  ul {
    padding-left: 18px;

    li {
      text-indent: -5px;
    }
  }

  ol {
    padding-left: 18px;
  }
} // .tui-editor-contents