.form {
  $blank: &;

  &--upload {
    margin-bottom: 20px;

    div {
      display: flex;
    }
  } // &--upload

  &__radio-label {
    color: $highlight-blue;
    font-size: 1.2rem;
    text-transform: capitalize;

    @media(min-width: 768px) {
      font-size: 1.5rem;
    }
  } // &__radio-label

  &__upload {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;

    & + label {
      display: flex;
      justify-content: space-between;
      align-content: center;
      cursor: pointer;
    }
  } // &__upload

  &__radio-wrapper {
    margin-bottom: 30px;
  }

  &__radio {
    display: flex;
    align-items: flex-start;
    position: relative;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    user-select: none;

    &:hover input ~ #{$blank}__radio-toggle {
      background-color: #83b4c7;
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;

      &:checked ~ #{$blank}__radio-toggle::after {
        display: block;
      }
    } // input
  } // &__radio

  &__radio-option {
    order: 2;
    flex: 1;
    color: $highlight-blue;
    font-size: 1rem;
    display: block;
  }

  &__radio-toggle {
    display: block;
    order: 1;
    margin-right: 10px;
    margin-top: 2px;
    height: 20px;
    width: 20px;
    border: 1px solid $highlight-blue;
    border-radius: 50%;

    &::after {
      content: "";
      display: none;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: $highlight-blue;
      border: 2px solid #fff;
    }
  } // &__radio-toggle

  &__search-input {
    font-size: 0.9rem;
    padding: 6px 15px;
    color: $body-text;
    height: 40px;
    border: 1px solid #C7C7C7;
    border-radius: 50px;
  }
} // .form